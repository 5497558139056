<template>
  <div>
    <component :is="getReportComponent"></component>
  </div>
</template>

<script>
//import LFZReport from './LFZReport';
//import EmployeeChangelog from './EmployeeChangelog';
//import PersonnelMasterSheet from './PersonnelMasterSheet';

export default {
  name: 'ReportsMain',
  //components: { LFZReport },
  computed: {
    getReportComponent() {
      const components = {
        //'employeedata': LFZReport,
        //'employeechangelog': EmployeeChangelog,
        //'personnelMasterSheet': PersonnelMasterSheet,
      };
      const tab = this.$route.params.tab;
      console.log(tab);
      return components[tab]// || LFZReport;
    },
  },
}
</script>

<style scoped>

</style>
